<template>
  <div class="email_bind">
    <div class="no_email" v-if="!$store.state.userInfo.email||isModify">
      <p class="title">{{$store.state.userInfo.email?'修改邮箱':'绑定邮箱'}}</p>
      <div class="bind_email">
        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
          <el-form-item
            label="邮箱"
            prop="email"
            :rules="[
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    ]"
          >
            <el-input type="email" placeholder="请输入邮箱" v-model="ruleForm.email" autocomplete="off"></el-input>
          </el-form-item>
          <el-row type="flex" justify="space-between">
            <el-col :span="14">
              <el-form-item
                prop="code"
                label="验证码"
                :rules="[
      { required: true, message: '请输入验证码', trigger: 'blur' },
    ]"
              >
                <el-input
                  type="password"
                  placeholder="请输入验证码"
                  v-model="ruleForm.code"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isShow" style="margin-top:39px;">
              <el-button type="primary" class="send_code" @click="sendCheckCode('ruleForm')">发送验证码</el-button>
            </el-col>
            <el-col :span="8" v-else style="margin-top:39px;">
              <el-button class="send_code">({{seconds}})s</el-button>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button
              style="width:100%"
              type="primary"
              @click="submitForm('ruleForm')"
            >{{$store.state.userInfo.email?'修改邮箱':'绑定邮箱'}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="had_email" v-if="$store.state.userInfo.email&&isModify==false">
      <p style="text-align:left;font-size:18px;font-weight:600">邮箱绑定</p>
      <p
        style="display:flex;justify-content:space-between;padding:20px 0;border-bottom:1px solid #dcdfe6"
      >
        <span>{{$store.state.userInfo.email}}</span>
        <span class="modify_text" @click="modifyEmail">修改</span>
      </p>
    </div>
  </div>
</template>
<script>
import { sendEmailCodeApi, bindEmailApi } from "api/AdminCenter";
export default {
  data() {
    return {
      ruleForm: {
        code: "",
        email: ""
      },
      isShow: true,
      seconds: "",
      timer: null,
      isModify: false
    };
  },
  methods: {
    modifyEmail() {
      this.isModify = true;
    },
    sendCheckCode(formName) {
      this.$refs[formName].validateField("email", error => {
        if (!error) {
          const TIME_seconds = 15;
          if (!this.timer) {
            this.seconds = TIME_seconds;
            this.isShow = false;
            this.timer = setInterval(() => {
              if (this.seconds > 0) {
                this.seconds--;
              } else {
                this.isShow = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);

            const { email } = this.ruleForm;
            sendEmailCodeApi({ email });
          }
        }
      });
    },
    submitForm(formName) {
      //登录提交
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {};
          //邮箱登录
          const { email, code } = this.ruleForm;
          params = { email, code };
          bindEmailApi(params).then(res => {
            if (res.code == 0) {
              this.$store.dispatch({
                type: "getUserInfo"
              });
              this.isModify = false;
              this.isShow = true;
            }
          });
        }
      });
    }
  },
  mounted() {
    console.log(this.isModify);
  }
};
</script>
<style lang="scss">
.email_bind {
  padding: 16px;
  padding-top: 80px;
  .no_email {
    .title {
      padding-bottom: 35px;
      font-size: 30px;
      color: #517cfc;
    }
    .send_code {
      width: 100%;
    }
  }
  .had_email {
    .modify_text {
      color: #517cfc;
      cursor: pointer;
    }
  }
}
</style>